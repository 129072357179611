import React from "react";

import logo from "../components/appready-logo.png";

const Header = () => {
  
  return (
    <>
      <div className="container">
        <div>
          <img className="logo" src={logo} alt="My Image" />
        </div>
        <div className="title">
          <div>App Ready Courses</div>
        </div>
      </div>
    </>
  );
};

export default Header;
