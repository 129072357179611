// import logo from './logo.svg';
import './App.css';
import { useState } from "react";

import Header from './components/Header';
import Hero from './components/Hero'



const App = () => {

  const [image, setImage] = useState([
    {
        id: 1,
        title: 'Angular FullStack',
        url: "https://ik.imagekit.io/9kllv04exw/ecom-angular.jpg?updatedAt=1714908592897"
    }
  ])

  return (
    <div className="App">
      <Header />
      <Hero image={image}/>
    </div>
  );
}

export default App;
