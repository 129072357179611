import React from "react";

import url1 from "../components/ecom-angular.jpg";

const Hero = ({ image }) => {
  return (
    <>
      <div className="hero-container">
        <h1 className="hero-title-big">24 +</h1>
        <div className="hero-title-smaill-flex">
          <h5 className="hero-title-small">hours</h5>
        </div>
      </div>
      <h3 className="hero-subtitle">
        E-commerce Full Stack Web Development Bootcamp 2023
      </h3>
      <p className="hero-intro">
        With this course, you can build a fully workable E-commerce website that
        includes Frontend, Backend, HTML, CSS, Javascript, Frameworks, UI/ UX,
        Database, 
      </p>
      <div>
        {image.map((course) => {
          return (
            <>
              <div>
                {/* {console.log("image", course)} */}
                <img
                  key={course.id}
                  className="courses-image"
                  src={course.url}
                  alt="My Image"
                />
                <div className="hero-atag-center">

                <a
                  className="hero-intro-subtitle"
                  href="https://www.udemy.com/course/the-e-commerce-full-stack-web-development-bootcamp/learn/lecture/35804960#overview"
                  >
                  Check out this course on Udemy
                </a>
                </div>

                <div className="hero-down-center">
                  <i className=" fa-solid fa-arrow-right-to-bracket fa-rotate-90 down-icon"></i>
                </div>
              </div>
            </>
          );
        })}
        {/* <img className="courses-image" src={url1} alt="My Image" /> */}
      </div>
    </>
  );
};

export default Hero;
